<template>
    <svg
        viewBox="0 0 28 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M25.1334773,5.47908574 C25.144642,5.71836284 25.1492939,5.95763994 25.1492939,6.19781658 C25.1492939,13.5380464 19.37159,22 8.80611397,22 C5.56185413,22 2.54181758,21.0815717 0,19.5055812 C0.449376973,19.5559554 0.906197043,19.5829415 1.36952982,19.5829415 C4.06207011,19.5829415 6.53876059,18.694198 8.50373816,17.2045631 C5.99076259,17.1595862 3.86947998,15.5539109 3.1391261,13.3482439 C3.48895165,13.4130106 3.84901146,13.447193 4.21930553,13.447193 C4.74404386,13.447193 5.25110483,13.3806272 5.73304536,13.2528928 C3.10470178,12.7419553 1.12390763,10.4967085 1.12390763,7.80798953 C1.12390763,7.78370201 1.12390763,7.76031402 1.12483801,7.73692603 C1.89985047,8.15341211 2.785579,8.40348367 3.72713075,8.43226888 C2.18547932,7.43468128 1.17135737,5.7363536 1.17135737,3.80954328 C1.17135737,2.7912663 1.45419505,1.83685652 1.94916099,1.01737744 C4.78218973,4.37715174 9.01545107,6.58731651 13.7901977,6.81939731 C13.6915767,6.41280615 13.6413358,5.98912377 13.6413358,5.55374739 C13.6413358,2.48722247 16.2138561,0 19.3855458,0 C21.0379133,0 22.5311846,0.674653473 23.5778701,1.75319949 C24.8869247,1.50582655 26.1159661,1.04166496 27.2268483,0.405691622 C26.7970095,1.70462444 25.8861605,2.79216584 24.6999169,3.47851331 C25.8629008,3.34448215 26.9700615,3.04583555 28,2.60416241 C27.2305699,3.71779041 26.2555242,4.69648771 25.1334773,5.47908574"
        ></path>
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
