import { useJsonld } from '#jsonld';

export function useStructuredArticle(page, image) {
    const time = page?.body?.find((o) => o.type === 'recipe_time_block');
    if (!time) {
        useJsonld({
            '@context': 'https://schema.org',

            '@type': 'Article',

            headline: page?.title,

            image: page?.data?.images?.[0]?.src,

            datePublished: page?.meta?.first_published_at,
        });
    }
}
