import { useJsonld } from '#jsonld';

function getIsoTime(time) {
    if (!time) {
        return;
    }
    return `PT${time}M`;
}

function getVideo(video, date, page) {
    const videoObj = [
        {
            '@type': 'VideoObject',
            name: video?.value.title ? video?.value.title : page.title,
            embedUrl: `https://www.youtube.com/embed/${video?.value?.source?.value?.video?.id}`,
            uploadDate: date,
            thumbnailUrl: `https://img.youtube.com/vi/${video?.value?.source?.value?.video?.id}/0.jpg`,
        },
    ];
    if (!video) {
        return [];
    }
    return videoObj;
}

export function useStructuredRecipe(page) {
    const ingredients = page?.body?.find(
        (o) => o.type === 'recipe_ingredients_block',
    );
    const instructions = page?.body?.find(
        (o) => o.type === 'recipe_instructions_block',
    );
    const time = page?.body?.find((o) => o.type === 'recipe_time_block');
    const video = page?.body?.find((o) => o.type === 'video_block');
    const author = page?.body?.find((o) => o.type === 'quotation_block');
    const ingredientsArray = [];
    if (ingredients) {
        ingredients.value.ingredients.forEach((element) => {
            ingredientsArray.push(`${element.amount} ${element.name}`);
        });
    }
    let tags = '';
    page?.tags?.map((x) => {
        tags = tags.concat(`${x.name}, `);
    });
    const steps = instructions?.value.steps.map((x) => {
        // strips potential html from the instrucion steps.
        return x.replace(/<(.|\n)*?>/g, '');
    });
    if (time) {
        useJsonld({
            '@context': 'https://schema.org',

            '@type': 'Recipe',
            datePublished: page.meta.first_published_at,
            name: page.title,
            image: page?.data?.images?.[0]?.src,
            recipeIngredient: ingredientsArray,
            recipeInstructions: steps,
            prepTime: getIsoTime(time?.value.timeActive),
            recipeCategory: ['dinner', 'lunch'],
            totalTime: getIsoTime(time?.value.timeTotal),
            recipeYield: time?.value.people,
            description: page?.data?.description,
            keywords: tags,
            video: getVideo(video, page?.meta?.first_published_at, page),
            author: [
                {
                    '@type': 'Person',
                    name: author?.value?.person?.name,
                    url: author?.value?.person?.website,
                },
            ],
        });
    }
}
