<template>
    <svg
        viewBox="0 0 20 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M9.166675,10 L5,10 C3.61666667,10 2.4375,9.5125 1.4625,8.5375 C0.4875,7.5625 0,6.38333333 0,5 C0,3.61666667 0.4875,2.4375 1.4625,1.4625 C2.4375,0.4875 3.61666667,0 5,0 L9.166675,0 L9.166675,1.66665 L5,1.66665 C4.07406667,1.66665 3.287025,1.99035 2.638875,2.63775 C1.990725,3.28513333 1.66665,4.07124167 1.66665,4.996075 C1.66665,5.92090833 1.990725,6.70833333 2.638875,7.35835 C3.287025,8.00835 4.07406667,8.33335 5,8.33335 L9.166675,8.33335 L9.166675,10 Z M6.083325,5.833325 L6.083325,4.166675 L13.916675,4.166675 L13.916675,5.833325 L6.083325,5.833325 L6.083325,5.833325 Z M10.833325,10 L10.833325,8.33335 L15,8.33335 C15.9259333,8.33335 16.712975,8.00965 17.361125,7.36225 C18.009275,6.71486667 18.33335,5.92875833 18.33335,5.003925 C18.33335,4.07909167 18.009275,3.29166667 17.361125,2.64165 C16.712975,1.99165 15.9259333,1.66665 15,1.66665 L10.833325,1.66665 L10.833325,0 L15,0 C16.3833333,0 17.5625,0.4875 18.5375,1.4625 C19.5125,2.4375 20,3.61666667 20,5 C20,6.38333333 19.5125,7.5625 18.5375,8.5375 C17.5625,9.5125 16.3833333,10 15,10 L10.833325,10 L10.833325,10 Z"
        ></path>
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
