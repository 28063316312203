<template>
    <div class="spacer-navigation-overlap row">
        <div class="col-12 item__media">
            <div v-if="header?.images?.length > 1" class="slider">
                <Slider
                    :wrapAround="true"
                    :showCurrent="true"
                    :slides="header?.images"
                    :itemsToShow="1"
                    :breakpoints="{}"
                >
                    <template #default="image">
                        <NuxtImg
                            sizes="xs:630 sm:767px md:1023px lg:1710px"
                            densities="1x 2x"
                            format="webp"
                            :src="image?.src"
                            :alt="image?.alt"
                            loading="lazy"
                            class="slider__image"
                            :placeholder="[300, 200, 60, 2]"
                        />
                    </template>
                </Slider>
                <hr />
            </div>
            <div v-else class="image">
                <NuxtImg
                    sizes="xs:630 sm:767px md:1023px lg:1710px"
                    densities="1x 2x"
                    format="webp"
                    :src="header?.images[0]?.src"
                    loading="lazy"
                    :alt="header?.images[0]?.alt"
                    :placeholder="[300, 200, 60, 2]"
                />
            </div>
        </div>
    </div>
    <div class="row justify-content-center item">
        <div class="col-12 col-800-10 col-1200-8">
            <PageBreadcrumbs :items="page?.breadcrumbs" class="spacer-50-40" />
            <h1
                class="scale-6 scale-6--bold-uppercase item__title"
                :id="slugifyString(page.title)"
            >
                {{ page.title }}
            </h1>
            <div class="scale-2 scale-2--lead">
                {{ header?.description }}
            </div>
            <ul class="icons">
                <li>
                    <FormButton
                        class="l-button--secondary l-button--icon-only icons__button"
                        component="NuxtLink"
                        :to="`https://www.facebook.com/share.php?u=${page?.meta?.html_url}`"
                        target="_blank"
                        :size40="true"
                    >
                        <IconsFacebook />
                    </FormButton>
                </li>
                <li>
                    <FormButton
                        class="l-button--secondary l-button--icon-only icons__button"
                        component="NuxtLink"
                        :to="`https://twitter.com/intent/tweet?text=${page?.title}&url=${page?.meta?.html_url}`"
                        target="_blank"
                        :size40="true"
                    >
                        <IconsTwitter />
                    </FormButton>
                </li>
                <li>
                    <FormButton
                        class="l-button--secondary l-button--icon-only icons__button"
                        @click="handleMail(page.title, page?.meta?.html_url)"
                        :size40="true"
                    >
                        <IconsMail />
                    </FormButton>
                </li>
                <li>
                    <FormButton
                        class="l-button--secondary l-button--icon-only icons__button"
                        @click="handleCopy(page?.meta?.html_url)"
                        :size40="true"
                    >
                        <IconsLink />
                    </FormButton>
                </li>
                <li>
                    <FormButton
                        class="l-button--secondary l-button--icon-only icons__button"
                        @click="handlePrint()"
                        :size40="true"
                    >
                        <IconsPrint />
                    </FormButton>
                </li>
            </ul>
        </div>
    </div>
    <div
        v-if="page?.body.length > 0"
        :class="{ 'spacer-footer': !page?.tags, 'spacer-180-120': page?.tags }"
    >
        <CmsBody :page="page" />
    </div>
    <div v-if="page?.tags" class="row justify-content-center spacer-footer">
        <div class="col-12 col-800-10 col-1200-8">
            <ul class="tags">
                <li v-for="tag in page?.tags">
                    <Tag :name="tag.name" :to="tag.to" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStructuredRecipe } from '~/composables/structuredData/recipe';
import { useStructuredArticle } from '~/composables/structuredData/article';
import { useHrefLang } from '~/composables/seo/hreflang';
import { slugifyString } from '~/utils/slugify';

const router = useRouter();

function handleMail(title, link) {
    const mailto = `mailto:?subject=${title}
        &body=${link}
    `;
    window.location.href = mailto;
}

function handleCopy(value) {
    navigator.clipboard.writeText(value);
    router.replace({ query: { alert: 'linkCopied' } });
}

function handlePrint() {
    window.print();
}

const header = computed(() => page?.value?.data);

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

if (!page?.value?.meta?.search_description && page?.value?.data?.description) {
    useHead({
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: page.value?.data?.description,
            },
            {
                property: 'og:description',
                content: page.value?.data?.description,
            },
        ],
    });
}

useStructuredRecipe(page.value);
useStructuredArticle(page.value);
useHrefLang(page?.value?.meta?.hreflang);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.item {
    margin-bottom: clamp(80px, 1.39vw + 75.56px, 100px);

    &__media {
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 15px;
    }
}

.slider {
    @include media-breakpoint-down(560) {
        :deep(.slider) {
            margin-left: $padding-container-negative;
            margin-right: $padding-container-negative;
        }

        :deep(.slider__navigation) {
            margin-left: (-$padding-container-negative);
            margin-right: (-$padding-container-negative);
        }
    }

    &__image {
        height: 60vh;
        min-height: 400px;
        max-height: clamp(400px, 27.78vw + 311.11px, 800px);
        width: 100%;
        object-fit: cover;
    }

    :deep(.slider__slide) {
        padding: 0;
    }

    hr {
        color: $color-dark-01;
        opacity: 1;
        margin-top: 10px;
        margin-bottom: 0;
    }
}

.image {
    height: 60vh;
    min-height: 400px;
    max-height: clamp(400px, 27.78vw + 311.11px, 800px);
    width: 100%;

    @include media-breakpoint-down(560) {
        margin-left: $padding-container-negative;
        margin-right: $padding-container-negative;
        width: auto;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.icons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    li:not(:last-child) {
        margin-right: 10px;
    }

    &__button {
        :deep(svg) {
            height: 24px !important;
            width: 24px !important;
        }
    }
}

.tags {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    li:not(:last-child) {
        margin-right: 15px;
        margin-bottom: 15px;
    }
}
</style>
